import React from 'react';
import Layout from '../components/layout/layout';
import { TopSection } from '../components/topsection';
import Wrapper from '../components/layout/wrapper';
import GroupPhoto from '../images/environment/group.jpg';
import Workshop from '../images/environment/workshop-4.jpg';
import { Flex, FlexChild } from '../components/layout/flex';
import SEO from '../components/seo'

const AboutPage = () => (
  <Layout>
    <SEO title={'Om oss'} />
    <TopSection title='Vi er Caulis' orange='3' />
    <article id='about-us'>
      <Wrapper center>
        <h2>Vi ser helheten.</h2>
        <p className='larger'>
          I Caulis er vi opptatt av helheten. Vi mener det er lite
          hensiktsmessig, og veldig ofte bortkastet tid og penger, å
          optimalisere innenfor én del av kundereisen uten å se den i
          tilknytning til resten. Kombinasjonen av management consultants og
          spesialister innenfor digitale fagområder, gjør at vi kan
          identifisere, initiere og levere de tiltakene som passer best for dine
          kunder der de befinner seg i kundereisen.
        </p>
      </Wrapper>
    </article>
    <Wrapper center>
      <div className='image-container group-photo-container'>
        <img className='group-photo' src={GroupPhoto} alt='' />
      </div>
    </Wrapper>
    <section id='values'>
      <Wrapper center>
        <h2>Hva er viktig for oss som jobber i Caulis?</h2>
        <article className='single-value'>
          <h3>Vi skal alltid utfordre</h3>
          <p>
            Vi er utfordrere. Caulis ble til gjennom et ønske om å utfordre og forbedre eksisterende arbeidsmetoder i den digitale verdenen, og vi lover at vi vil fortsette å utfordre både bransjen, kundene våre og hverandre.
          </p>
        </article>
        <article className='single-value'>
          <h3>Vi blir bedre sammen</h3>
          <p>
            Vi er alle unike og kan bidra med forskjellige ting på forskjellige måter. Vi tror derfor på at vi og det vi leverer blir bedre når vi samarbeider, dyrker forskjeller og respekterer hverandre. Det er på den måten vi får det gøy på jobb!
          </p>
        </article>
        <article className='single-value'>
          <h3>Vi tar ansvar</h3>
          <p>
            Vi er veldig stolte av arbeidet vi gjør. Det betyr at du som kunde alltid kan stole på at vi leverer arbeid av høyeste kvalitet, er åpne og ærlige, jobber med transparens i prosessene våre og at vi alltid setter kunden først. Vi skal opptre etisk og med integritet.
          </p>
        </article>
      </Wrapper>
    </section>
    <section id='about-name'>
      <Wrapper center>
        <Flex margin='3'>
          <FlexChild width={'100%'}>
            <h2>Caulis betyr stilk på latin</h2>
            <p className='larger'>
              En stilk eller stengel er et planteorgan som har til hovedoppgave
              å bære bladene i en gunstig stilling i forhold til lyset, og lede
              vann og næringsstoffer for å sikre at bladet og planten kan leve
              og vokse.
            </p>
          </FlexChild>
          <FlexChild width={'100%'}>
            <div className='image-container' name='workshop-image'>
              <img className='' src={Workshop} alt='' />
            </div>
          </FlexChild>
        </Flex>
      </Wrapper>
    </section>
  </Layout>
);

export default AboutPage;
